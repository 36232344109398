// third-party
// import { FormattedMessage } from "react-intl";

// assets
import { WalletMoney } from "iconsax-react";

// type
import { NavItemType } from "types/menu";
import { EPermissionSection } from "utils/permissions/types";

// icons
const icons = {
  formsTable: WalletMoney,
  paymentMethods: WalletMoney,
};

// ==============================|| MENU ITEMS - FORMS & TABLES ||============================== //

const financial: NavItemType = {
  id: "group-forms-tables",
  title: "Finance",
  icon: icons.formsTable,
  type: "group",
  children: [
    {
      id: "finance",
      title: "Payment Methods",
      type: "item",
      url: "/finance/payment-methods",
      icon: icons.paymentMethods,
      permissions: [EPermissionSection.PAYMENT_METHODS],
    },
  ],
};

export default financial;
