import { useContext, useMemo, useState } from "react";

// material-ui
import {
  Button,
  Chip,
  Drawer,
  FormControl,
  InputLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

// third-party
import {
  useTable,
  useFilters,
  useGlobalFilter,
  Column,
  HeaderGroup,
  Cell,
} from "react-table";

// project-imports
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import { EmptyTable } from "components/third-party/ReactTable";

import {
  GlobalFilter,
  DefaultColumnFilter,
  renderFilterTypes,
} from "utils/react-table";
import { Context } from "App";
import { StoreData } from "types/publisher";
import { enqueueSnackbar } from "notistack";
import { getStoreData } from "utils/apiCalls";

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, data }: { columns: Column[]; data: [] }) {
  const filterTypes = useMemo(() => renderFilterTypes, []);
  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
  const initialState = useMemo(
    () => ({ filters: [{ id: "status", value: "" }] }),
    []
  );
  const [addUserDrawerOpen, setAddUserDrawerOpen] = useState(false);
  const [fullName, setFullName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const { globalPublisherData } = useContext<any>(Context);
  const [publisherData, setPublisherData] = globalPublisherData;

  const clearFields = () => {
    setFullName("");
    setUserEmail("");
  };

  const submitNewUser = () => {
    const url = `${
      process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL
    }/admin-dashboard/users/${(publisherData as StoreData).publisher._id}`;

    // Make the fetch request with FormData
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("__ac_token")}`,
      },
      body: JSON.stringify({
        name: fullName,
        email: userEmail,
        role: "admin",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // if not 200 status code
        if (data.status !== 200) {
          enqueueSnackbar("Something went wrong inviting a new user", {
            variant: "error",
          });
          setAddUserDrawerOpen(false);
          clearFields();
          return;
        }
        console.log("Success:", data);
        setAddUserDrawerOpen(false);
        enqueueSnackbar("New user invited!");
        clearFields();
        getStoreData((publisherData as StoreData).publisher._id, (data) => {
          setPublisherData(data);
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState,
      filterTypes,
    },
    useGlobalFilter,
    useFilters
  );

  const sortingRow = rows.slice(0, 10);

  return (
    <>
      <Drawer
        anchor="right"
        open={addUserDrawerOpen}
        onClose={() => setAddUserDrawerOpen(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: { xs: "100%", sm: "75%", md: "350px" },
            padding: 3,
          },
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
          Invite User
        </Typography>
        <Stack>
          <Stack py={1} spacing={4}>
            <Stack spacing={1}>
              <InputLabel>Full Name</InputLabel>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  value={fullName}
                  helperText="For example: John Doe"
                  onChange={(e) => {
                    setFullName(e.target.value);
                  }}
                />
              </FormControl>
            </Stack>
            <Stack spacing={1}>
              <InputLabel>User Email</InputLabel>
              <FormControl fullWidth>
                <TextField
                  value={userEmail}
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                  }}
                  helperText="The invitation email could arrive to the publishers Spam inbox, please be aware of this and update the publisher."
                />
              </FormControl>
            </Stack>
            <Stack spacing={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  submitNewUser();
                }}
              >
                Invite User
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Drawer>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        sx={{ padding: 2 }}
      >
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />

        <Button
          variant="contained"
          onClick={() => {
            setAddUserDrawerOpen(true);
          }}
        >
          Invite User
        </Button>
      </Stack>

      <Table {...getTableProps()}>
        <TableHead sx={{ borderTopWidth: 2 }}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: HeaderGroup) => (
                <TableCell
                  style={{ width: "calc(100% / 5)" }}
                  {...column.getHeaderProps([{ className: column.className }])}
                >
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {/* {headerGroups.map((group: HeaderGroup<{}>) => (
            <TableRow {...group.getHeaderGroupProps()}>
              {group.headers.map((column: HeaderGroup) => (
                <TableCell
                  style={{ width: "calc(100% / 7)" }}
                  {...column.getHeaderProps([{ className: column.className }])}
                >
                  {column.canFilter ? column.render("Filter") : null}
                </TableCell>
              ))}
            </TableRow>
          ))} */}
          {sortingRow.length > 0 ? (
            sortingRow.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell: Cell) => (
                    <TableCell
                      {...cell.getCellProps([
                        { className: cell.column.className },
                      ])}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })
          ) : (
            <EmptyTable msg="No Data" colSpan={7} />
          )}
        </TableBody>
      </Table>
    </>
  );
}

// ==============================|| REACT TABLE - FILTERING ||============================== //

const UsersTable = () => {
  const { globalPublisherData } = useContext<any>(Context);
  const [publisherData] = globalPublisherData;

  const columns = useMemo(
    () =>
      [
        {
          Header: "Name",
          accessor: "name",
        },
        {
          Header: "Active",
          accessor: "active",
          Cell: ({ value }: { value: boolean }) => (value ? "Yes" : "No"),
        },
        {
          Header: "Email",
          accessor: "email",
        },
        {
          Header: "Role",
          accessor: "role",
          Cell: ({ value }: { value: string }) => {
            if (!value) return "N/A";
            const capitalizedValue =
              value?.charAt(0).toUpperCase() + value?.slice(1);
            return (
              <Chip
                variant="outlined"
                color={value === "admin" ? "primary" : "secondary"}
                label={capitalizedValue}
              />
            );
          },
        },
        {
          Header: "Last Login",
          accessor: "lastLogin",
          Cell: ({ value }: { value: Date | Date[] }) => {
            const options: Intl.DateTimeFormatOptions = {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour12: false, // 24-hour format
              timeZone: "UTC",
            };

            const dateValue = Array.isArray(value) ? value[0] : value;

            return dateValue
              ? new Date(dateValue).toLocaleString("en-GB", options)
              : "Never";
          },
        },
      ] as Column[],
    []
  );

  return (
    <MainCard content={false}>
      <ScrollX>
        <ReactTable
          columns={columns}
          data={((publisherData as StoreData)?.users || []) as any}
        />
      </ScrollX>
    </MainCard>
  );
};

export default UsersTable;
