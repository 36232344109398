import { useQuery, useMutation } from "@tanstack/react-query";
import { EQuery } from "enum/queries.enum";
import axiosServices from "utils/axios";

const fetchAdminUsers = async () => {
  return await axiosServices.get<any[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/admin/users`
  );
};

const addAdminUser = async ({
  email,
  role,
}: {
  email: string;
  role: string;
}) => {
  return await axiosServices.post<any[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/admin/users`,
    {
      userEmail: email,
      role,
    }
  );
};

const updateAdminUser = async ({
  email,
  role,
}: {
  email: string;
  role: string;
}) => {
  return await axiosServices.put<any[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/admin/users/${email}`,
    {
      role,
    }
  );
};

const deleteAdminUser = async (email: string) => {
  return await axiosServices.delete<any[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/admin/users/${email}`
  );
};

export default function useAdminUsers() {
  const getAdminUsers = useQuery({
    queryKey: [EQuery.GET_COMPANIES],
    queryFn: fetchAdminUsers,
  });

  const addAdminUserMutation = useMutation({
    mutationFn: addAdminUser,
  });

  const updateAdminUserMutation = useMutation({
    mutationFn: updateAdminUser,
  });

  const deleteAdminUserMutation = useMutation({
    mutationFn: deleteAdminUser,
  });

  return {
    getAdminUsers,
    addAdminUserMutation,
    deleteAdminUserMutation,
    updateAdminUserMutation,
  };
}
